//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'foot',
  props: {
    platformData: {},
    jumpUrl: {
      type: String
    }
  },
  methods: {
    goYs: function goYs() {
      this.$router.push('/yszc');
    },
    goTh: function goTh() {
      this.$router.push('/thzc');
    },
    goMj: function goMj() {
      this.$router.push('/mjzc');
    },
    goIndex: function goIndex() {
      this.$router.push('/');
    }
  }
};