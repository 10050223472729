export default {
  message: {
    "msg1": "Nacido en 2015, es una plataforma de compras en línea personalizada, segura y rápida para la región del sudeste asiático. la compañía planea un modelo de negocio muy avanzado y mutuamente beneficioso.",
    "msg2": "La Plataforma de compras en línea preferida en el sudeste asiático",
    "msg3": "Entrar en el centro comercial",
    "msg4": "Estrategia empresarial avanzada",
    "msg5": "Adoptar un modelo de beneficio mutuo para crear prosperidad juntos.",
    "msg6": "Gigantes minoristas en el sudeste asiático",
    "msg7": "Líder del mercado, ganando la confianza de los consumidores.",
    "msg8": "Convergencia de marcas globales y locales",
    "msg9": "Le ofrece una experiencia de compra de ventanilla única internacional y local.",
    "msg10": "Sobre",
    "msg11": "Con sede en Singapur.",
    "msg12": "Ahora es el principal centro comercial en línea en el mercado del sudeste asiático, proporcionando un servicio de compras de ventanilla única para muchas marcas internacionales y locales.",
    "msg13": "Nuestro mercado",
    "msg14": "En la actualidad, los principales mercados son singapur, malasia, indonesia, tailandia, Vietnam y taiwán, china.",
    "msg15": "2023 comienza a centrarse en el desarrollo del mercado japonés.",
    "msg16": "Más regiones, por favor esperen.",
    "msg17": "Misión",
    "msg18": "Es proporcionar una plataforma de comercio global en la que cualquiera pueda comerciar cualquier cosa.",
    "msg19": "Política de privacidad",
    "msg20": "Introducción",
    "msg21": "Bienvenido al sitio web de la tienda u - Tesco (individual y colectivamente conocido como \"u - tesco\", \"nosotros\", \"nuestros\" o \"nuestros\"). U - Tesco cumple concienzudamente con su responsabilidad de aplicar las disposiciones de las leyes y reglamentos de privacidad (\"ley de privacidad\") y se compromete a respetarnos, los derechos de privacidad y preocupaciones de todos los usuarios de los sitios web y aplicaciones móviles de U - Tesco (\"plataformas\") (denominamos colectivamente la Plataforma y los servicios que ofrecemos en la plataforma como \"servicios\"). Los usuarios se refieren a los usuarios que nos registran cuentas para usar el servicio, incluidos compradores y vendedores (individuales y colectivamente conocidos como \"usuarios\", \"usted\" o \"su\"). Reconocemos la importancia de los datos personales que nos ha confiado y creemos que tenemos la responsabilidad de gestionar, proteger y procesar adecuadamente sus datos personales. Esta política de privacidad (\"política de privacidad\" o \"política\") tiene como objetivo ayudarle a comprender cómo recopilamos, utilizamos, divulgamos y Procesamos los datos personales que nos proporciona y los datos personales que tenemos sobre usted, tanto ahora como en el futuro, y ayudarlo a tomar decisiones inteligentes antes de proporcionarnos los datos de cualquiera de sus personas.",
    "msg22": "Los datos personales se refieren a los datos sobre personas que pueden identificarse a partir de esos datos o de otra información a la que la Organización ya tiene o puede tener acceso, sean o no reales. Ejemplos comunes de datos personales incluyen nombre, número de identificación e información de contacto.",
    "msg23": "Al utilizar el servicio, registrar una cuenta con nosotros, acceder a nuestra plataforma o acceder al servicio, usted reconoce y acepta que acepta las prácticas, requisitos y políticas esbozadas en esta política de privacidad. Usted está de acuerdo en que recopilamos, utilizamos, divulgamos y Procesamos sus datos personales de acuerdo con lo descrito aquí. Si no está de acuerdo en procesar sus datos personales como se describe en esta política de privacidad, no use nuestros servicios ni acceda a nuestra plataforma. Si cambiamos nuestra política de privacidad, le informaremos publicando estos cambios o políticas de privacidad revisadas en nuestra plataforma. Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. En la mayor medida posible permitida por la Ley aplicable, puede usar los servicios en la plataforma, incluido el pedido.",
    "msg24": "Esta política se aplica junto con otras notificaciones, cláusulas contractuales y términos de consentimiento aplicables a nuestra recopilación, almacenamiento, uso, divulgación y procesamiento de sus datos personales, y no tiene intención de revocar estas notificaciones o cláusulas a menos que especifiquemos otra cosa.",
    "msg25": "Salvo que se disponga expresamente otra cosa, esta política se aplica a los compradores y vendedores que utilicen este servicio.",
    "msg26": "¿¿ cuándo recopilará u - Tesco datos personales?",
    "msg27": "Recopilaremos / podríamos recopilar datos personales sobre usted:",
    "msg28": "Cuando visite y visite nuestro sitio web;",
    "msg29": "Al presentar cualquier solicitud o formulario de trabajo, incluido, pero no limitado a, el formulario de solicitud u otro formulario relacionado con la publicación de cualquiera de nuestros puestos;",
    "msg30": "Cuando interactúa con nosotros por teléfono (posiblemente grabado), carta, fax, reunión cara a cara, entrevista, Plataforma de redes sociales, correo electrónico o a través de la Web. Incluye, pero no se limita a, las cookies que podemos desplegar cuando visite y visite nuestro sitio web;",
    "msg31": "Podemos recopilar datos personales sobre usted de usted, nuestros afiliados, terceros y otras fuentes, incluyendo, pero no limitado a, socios comerciales (como proveedores de servicios logísticos o de pago), agencias de crédito o calificación, proveedores o socios de servicios de marketing, recomendadores o programas de fidelización, otros usuarios de nuestros servicios o fuentes de datos públicamente disponibles o gubernamentales.",
    "msg32": "En algunos casos, puede proporcionarnos datos personales de otras personas, como su familia o amigos o personas en su lista de contactos. Si nos proporciona sus datos personales, declara y garantiza que ha obtenido su consentimiento para procesar sus datos personales de acuerdo con esta política.",
    "msg33": "¿¿ qué datos personales recopila u - tesco?",
    "msg34": "Los datos personales que puede recopilar u - Tesco incluyen, pero no se limitan a:",
    "msg35": "Nombre;",
    "msg36": "Dirección de correo electrónico;",
    "msg37": "Fecha de nacimiento;",
    "msg38": "Dirección familiar;",
    "msg39": "Número de teléfono;",
    "msg40": "Usted está de acuerdo en no presentarnos ninguna información inexacta o engañosa, y está de acuerdo en informarnos de cualquier inexactitud o cambio en esta información. Nos reservamos el derecho de solicitar más documentos para verificar cualquier información que proporcione.",
    "msg41": "Política de devolución",
    "msg42": "He cambiado de opinión.",
    "msg43": "Puede devolver la mercancía dentro de los 15 días posteriores a la recogida o entrega.",
    "msg44": "Sus artículos deben ser:",
    "msg45": "No se utiliza y tiene todos los componentes originales;",
    "msg46": "En el embalaje original (si es aplicable, con etiqueta);",
    "msg47": "Soporte de productos",
    "msg48": "En la mayoría de los casos, si tiene algún problema con los artículos, puede ver cómodamente en casa nuestro sitio web de soporte de productos para ver si podemos resolver el problema. Allí puede acceder a preguntas frecuentes sobre productos y nuestra empresa, transporte y recogida, devolución y reembolso.",
    "msg49": "Hay un problema con mi mercancía.",
    "msg50": "Si todavía necesita devolver (dentro de los primeros 15 días), le proporcionaremos un reemplazo, reemplazo o reembolso.",
    "msg51": "Después de este plazo, mientras el producto siga bajo garantía, Utilizaremos nuestro agente de mantenimiento profesional para organizar la reparación o, si no se puede reparar, reemplazaremos o reemplazaremos su mercancía. La forma más rápida de devolver la mercancía es a través de nuestros centros de almacenamiento globales en 112 países. Podemos aceptar la mayoría de los artículos, excepto los grandes, incluso si se entregan inicialmente a domicilio. Encuentra la tienda más cercana a ti. Si desea devolver un producto grande, desplácese hacia abajo para iniciar una conversación en tiempo real o vaya a nuestra línea de ayuda.",
    "msg52": "Entrega y recogida",
    "msg53": "¿¿ dónde entregas?",
    "msg54": "Para saber si transportamos a su región, introduzca su código postal en los productos que desea comprar. Los productos se envían directamente desde nuestros proveedores, y nuestros almacenes globales se encuentran en 112 países.",
    "msg55": "¿¿ a dónde ir a recogerlo?",
    "msg56": "Operamos las 24 / 7 las 24 horas del día en los puntos de recogida de las tiendas u - Tesco y los almacenes globales.",
    "msg57": "Para los artículos que se pueden utilizar para la entrega por vía rápida, elija entre varios períodos de tiempo en función de su ubicación. Los pedidos se entregarán a domicilio y se almacenarán en un lugar seguro y confiable.",
    "msg58": "Preguntas frecuentes",
    "msg59": "¿¿ qué pasa si pido artículos grandes y pequeños al mismo tiempo?",
    "msg60": "La forma más rápida de enviarlos a usted es transportarlos por separado. No te preocupes; Solo tiene que pagar el flete una vez. Vea el correo electrónico de confirmación de su pedido para obtener más información.",
    "msg61": "¿¿ qué pasa si no estoy en casa?",
    "msg62": "Los objetos pequeños permanecerán en lugares seguros y confiables, en lugares invisibles o entregados a sus vecinos a petición suya. Las piezas grandes o valiosas requieren una foto o firma y no se pueden dejar, por lo que alguien debe recogerlas en casa. ¡Si no puede llegar, ¡ Póngase en contacto con nosotros a través de la línea de ayuda para que reprogramamos la hora de entrega y seleccionemos la fecha y el período de tiempo más adecuados para usted!",
    "msg63": "Servicio al cliente",
    "msg64": "Servicio al cliente en línea",
    "msg65": "Descarga de la aplicación (comprador)",
    "msg66": "Descarga de la aplicación (vendedor)",
    "msg67": "Devolución e intercambio",
    "msg68": "Política de privacidad",
    "msg69": "Política de devolución",
    "msg70": "Entrega y recogida",
    "msg71": "Centro de usuarios",
    "msg72": "Registro de usuarios",
    "msg73": "El comerciante se conecta",
    "msg74": "Protocolo de usuario",
    "msg75": "Sobre nosotros",
    "msg76": "Reclutar talentos",
    "msg77": "Declaración de los esclavos modernos"
  }
};