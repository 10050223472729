import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.array.index-of";
import { servicePost, serviceGet } from '@/utils/http';
import api from '@/api/index';
var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
export default {
  logout: function logout() {
    return new Promise(function (resolve, reject) {
      window.localStorage.removeItem('USER_TOKEN');
      resolve(true);
    });
  },
  isLogin: function isLogin() {
    return new Promise(function (resolve, reject) {
      var token = window.localStorage.getItem('USER_TOKEN');

      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },
  login: function login(_ref, requestParam) {
    var dispatch = _ref.dispatch,
        commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      serviceGet(api.bankLogin, requestParam).then(function (res) {
        var data = res.data.data || null;
        commit('setUserToken', data);
        window.localStorage.setItem('setTime', res.data.currentTime);
        window.localStorage.setItem('loginName', res.data.loginName);
        window.localStorage.setItem('userName', res.data.nickname);
        window.localStorage.setItem('userType', res.data.userType);

        if (data !== null) {
          window.localStorage.setItem('USER_TOKEN', data); // dispatch('getSign',{token: data.token, url: window.location.href})

          resolve(true);
        } else {
          _Toast.fail(res.data.header.errMsg);
        }
      })["catch"](reject);
    });
  }
};