export default {
  message: {
    "msg1": "Geboren im 2015 ist es eine sichere und schnelle Online-Shopping-Plattform, die speziell auf den südostasiatischen Raum zugeschnitten ist und ein hochentwickeltes und für beide Seiten vorteilhaftes Geschäftsmodell geplant hat.",
    "msg2": "Südostasiens bevorzugte Online-Shopping-Plattform",
    "msg3": "Betreten Sie das Einkaufszentrum",
    "msg4": "Fortschrittliche Geschäftsstrategie",
    "msg5": "Ein für beide Seiten vorteilhaftes Modell annehmen und gemeinsam Wohlstand schaffen.",
    "msg6": "Handelsriesen in Südostasien",
    "msg7": "Marktführer, gewinnen das Vertrauen der Verbraucher.",
    "msg8": "Konvergenz globaler und lokaler Marken",
    "msg9": "Wir bieten Ihnen ein Einkaufserlebnis aus einer Hand sowohl international als auch lokal.",
    "msg10": "über",
    "msg11": "Der Hauptsitz befindet sich in Singapur.",
    "msg12": "Es ist heute ein führendes Online-Einkaufszentrum auf dem südostasiatischen Markt und bietet One-Stop-Shopping-Dienstleistungen für zahlreiche internationale und lokale Marken.",
    "msg13": "Unser Markt",
    "msg14": "Derzeit sind die Hauptmärkte Singapur, Malaysia, Indonesien, Thailand, Vietnam und Taiwan, China",
    "msg15": "Ab 2023 konzentrieren wir uns auf die Entwicklung des japanischen Marktes.",
    "msg16": "Weitere Regionen, bleiben Sie dran",
    "msg17": "Unsere Mission",
    "msg18": "Es soll eine globale Handelsplattform bieten, auf der jeder jeden Artikel handeln kann.",
    "msg19": "Datenschutzerklärung",
    "msg20": "kurze Einführung",
    "msg21": "Willkommen auf der U-TESCO Store Website (einzeln und gemeinsam als \"U-TESCO\", \"uns\", \"unser\" oder \"unser\" bezeichnet). U-TESCO erfüllt gewissenhaft seine Verpflichtungen gemäß den geltenden Datenschutzgesetzen und -vorschriften (das \"Datenschutzgesetz\") und verspricht, die Datenschutzrechte und Bedenken aller Nutzer der U-TESCO Website und mobilen Anwendungen (die \"Plattform\") zu respektieren (wir bezeichnen die Plattform und die Dienste, die wir auf der Plattform bereitstellen, gemeinsam als die \"Dienste\"). Nutzer beziehen sich auf Nutzer, die Konten bei uns registrieren, um den Dienst zu nutzen, einschließlich Käufer und Verkäufer (einzeln und gemeinsam als \"Benutzer\", \"Sie\" oder \"Ihr\" bezeichnet). Wir sind uns der Bedeutung der personenbezogenen Daten bewusst, die Sie uns anvertraut haben, und glauben, dass wir die Verantwortung tragen, Ihre personenbezogenen Daten ordnungsgemäß zu verwalten, zu schützen und zu verarbeiten. Diese Datenschutzrichtlinie (\"Datenschutzrichtlinie\" oder \"Richtlinie\") soll Ihnen helfen zu verstehen, wie wir personenbezogene Daten, die Sie uns zur Verfügung stellen und die wir über Sie besitzen, erfassen, verwenden, offenlegen und verarbeiten, und Ihnen dabei helfen, fundierte Entscheidungen zu treffen, bevor Sie uns Ihre personenbezogenen Daten zur Verfügung stellen.",
    "msg22": "Personenbezogene Daten sind Daten über Personen, die anhand dieser Daten oder anderer Informationen identifiziert werden können, auf die die Organisation Zugriff hat oder haben kann, unabhängig davon, ob sie echt sind oder nicht. Häufige Beispiele für personenbezogene Daten sind Namen, ID-Nummern und Kontaktinformationen.",
    "msg23": "Durch die Nutzung des Dienstes, die Registrierung eines Kontos bei uns, den Zugriff auf unsere Plattform oder den Zugriff auf den Dienst erkennen Sie an und stimmen zu, dass Sie die in dieser Datenschutzerklärung beschriebenen Praktiken, Anforderungen und Richtlinien akzeptieren. Sie erklären sich damit einverstanden, dass wir Ihre personenbezogenen Daten wie hier beschrieben erheben, nutzen, offenlegen und verarbeiten. Wenn Sie mit der Verarbeitung Ihrer personenbezogenen Daten wie in dieser Datenschutzerklärung beschrieben nicht einverstanden sind, nutzen Sie bitte unsere Dienste nicht und greifen Sie nicht auf unsere Plattform zu. Wenn wir unsere Datenschutzerklärung ändern, werden wir Sie benachrichtigen, indem wir diese Änderungen oder überarbeitete Datenschutzrichtlinien auf unserer Plattform veröffentlichen. Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Soweit gesetzlich zulässig, können Sie die Dienste auf der Plattform nutzen, einschließlich der Auftragserteilung.",
    "msg24": "Diese Richtlinie gilt in Verbindung mit anderen Hinweisen, Vertragsbedingungen und Einwilligungsbedingungen, die für unsere Erhebung, Speicherung, Nutzung, Offenlegung und Verarbeitung Ihrer personenbezogenen Daten gelten.",
    "msg25": "Sofern nicht anders angegeben, gilt diese Richtlinie sowohl für Käufer als auch für Verkäufer, die diesen Service nutzen.",
    "msg26": "Wann erfasst U-TESCO personenbezogene Daten?",
    "msg27": "Wir werden/können personenbezogene Daten über Sie sammeln:",
    "msg28": "Wenn Sie unsere Website besuchen und darauf zugreifen;",
    "msg29": "Wenn Sie eine Bewerbung oder ein Formular einreichen, einschließlich, aber nicht beschränkt auf das Bewerbungsformular oder andere Formulare im Zusammenhang mit einer unserer Stellenausschreibungen;",
    "msg30": "Wenn Sie mit uns über Telefonanrufe (die aufgezeichnet werden können), Briefe, Faxe, persönliche Meetings, Interviews, Social-Media-Plattformen, E-Mails oder Websites interagieren. Dazu gehören unter anderem Cookies, die wir einsetzen können, wenn Sie unsere Website besuchen und darauf zugreifen;",
    "msg31": "Wir können personenbezogene Daten über Sie von Ihnen, unseren verbundenen Unternehmen, Dritten und anderen Quellen erfassen, einschließlich, aber nicht beschränkt auf Geschäftspartner (wie Logistik- oder Zahlungsdienstleister), Kredit- oder Ratingagenturen, Marketingdienstleister oder Partner, Empfehlungen oder Treueprogramme, andere Nutzer unserer Dienste oder öffentlich zugängliche oder staatliche Datenquellen.",
    "msg32": "In einigen Fällen können Sie uns personenbezogene Daten über andere Personen zur Verfügung stellen, z. B. Ihre Familie oder Freunde oder Personen auf Ihrer Kontaktliste. Wenn Sie uns ihre personenbezogenen Daten zur Verfügung stellen, erklären und garantieren Sie, dass Sie ihre Einwilligung zur Verarbeitung ihrer personenbezogenen Daten gemäß dieser Richtlinie eingeholt haben.",
    "msg33": "Welche personenbezogenen Daten erfasst U-TESCO?",
    "msg34": "Zu den personenbezogenen Daten, die U-TESCO sammeln kann, gehören unter anderem:",
    "msg35": "Name;",
    "msg36": "E-Mail-Adresse;",
    "msg37": "Geburtsdatum;",
    "msg38": "Wohnadresse;",
    "msg39": "Telefonnummer",
    "msg40": "Sie verpflichten sich, uns keine ungenauen oder irreführenden Informationen zu übermitteln, und Sie stimmen zu, uns über Ungenauigkeiten oder Änderungen dieser Informationen zu informieren. Wir behalten uns das Recht vor, weitere Dokumente anzufordern, um Ihre Angaben zu überprüfen.",
    "msg41": "Rückkehrpolitik",
    "msg42": "Ich habe meine Meinung geändert",
    "msg43": "Sie können den Artikel innerhalb von 15 Tagen nach Abholung oder Lieferung zurücksenden.",
    "msg44": "Ihr Artikel muss:",
    "msg45": "unbenutzt und mit allen Originalteilen;",
    "msg46": "In der Originalverpackung (gegebenenfalls mit Etiketten);",
    "msg47": "Produktsupport",
    "msg48": "In den meisten Fällen, wenn Sie irgendwelche Artikelprobleme haben, können Sie bequem unsere Produktsupport-Website zu Hause überprüfen, um zu sehen, ob wir das Problem lösen können. Dort finden Sie häufig gestellte Fragen zu Produkten und unserem Unternehmen, Transport und Abholung, Rückgabe und Rückerstattung.",
    "msg49": "Es gibt ein Problem mit meinen Waren",
    "msg50": "Wenn Sie noch eine Rücksendung benötigen (innerhalb der ersten 15-Tage), werden wir Ihnen einen Umtausch, Ersatz oder Rückerstattung anbieten.",
    "msg51": "Nach diesem Zeitraum, solange das Produkt noch unter der Garantie ist, werden wir unseren professionellen Reparaturagenten verwenden, um Reparaturen zu arrangieren, oder wenn Reparaturen nicht möglich sind, ersetzen oder ersetzen Sie Ihr Produkt. Der schnellste Weg, Waren zurückzusenden, ist über unsere globalen Lagerzentren in 112-Ländern/Regionen. Wir können die meisten Artikel außer großen Artikeln annehmen, auch wenn sie ursprünglich an unsere Haustür geliefert wurden. Finden Sie das nächste Geschäft in Ihrer Nähe. Wenn Sie einen großen Artikel zurückgeben möchten, scrollen Sie bitte nach unten, um einen Echtzeit-Chat zu starten oder gehen Sie zu unserem Hilfe-Hotline-Link.",
    "msg52": "Lieferung und Abholung",
    "msg53": "Wohin liefern Sie die Ware?",
    "msg54": "Um zu erfahren, ob wir in Ihre Region geliefert haben, geben Sie bitte Ihre Postleitzahl auf dem Artikel ein, den Sie kaufen. Die Produkte werden direkt von unseren Lieferanten versendet, und unsere globalen Lager befinden sich in 112-Ländern/Regionen.",
    "msg55": "Wo bekomme ich es?",
    "msg56": "Wir arbeiten rund um die Uhr an unseren Sammelstellen in U-TESCO Stores und globalen Lagern.",
    "msg57": "Für Artikel, die für eine schnelle Lieferung verwendet werden können, wählen Sie bitte aus mehreren Zeiträumen basierend auf Ihrem Standort. Die Bestellung wird an Ihre Haustür geliefert und an einem sicheren und zuverlässigen Ort gelagert.",
    "msg58": "Häufig gestellte Fragen",
    "msg59": "Was ist, wenn ich sowohl große als auch kleine Artikel gleichzeitig bestelle?",
    "msg60": "Der schnellste Weg, sie an Sie zu liefern, ist, sie separat zu versenden. Keine Sorge; Sie müssen die Versandkosten nur einmal bezahlen. Bitte überprüfen Sie Ihre Bestellbestätigungs-E-Mail für weitere Informationen.",
    "msg61": "Was ist, wenn ich nicht zu Hause bin?",
    "msg62": "Kleine Gegenstände werden an einem sicheren und zuverlässigen Ort, außer Sichtweite gelassen oder auf Wunsch an Ihre Nachbarn übergeben. Große oder wertvolle Gegenstände erfordern Fotos oder Unterschriften und können nicht zurückgelassen werden, daher muss jemand sie zu Hause abholen. Wenn Sie nicht anreisen können, kontaktieren Sie uns bitte über die Hilfe-Hotline, damit wir die Lieferzeit verschieben und den für Sie am besten geeigneten Termin und Zeitraum auswählen können!",
    "msg63": "Kundenservice",
    "msg64": "Online Service",
    "msg65": "App-Download (Käuferseite)",
    "msg66": "App Download (Verkäuferseite)",
    "msg67": "Rückgaben und Umtausch",
    "msg68": "Datenschutzerklärung",
    "msg69": "Rückkehrpolitik",
    "msg70": "Lieferung und Abholung",
    "msg71": "Benutzercenter",
    "msg72": "Benutzerregistrierung",
    "msg73": "Händleranmeldung",
    "msg74": "Nutzungsvereinbarung",
    "msg75": "Über uns",
    "msg76": "Rekrutierung von Talenten",
    "msg77": "Moderne Sklaven-Erklärung"
  }
};