import Vue from 'vue';
import VueRouter from "vue-router";
import index from '@/views/index';
import yszc from '@/views/yszc';
import thzc from '@/views/thzc';
import mjzc from '@/views/mjzc';
Vue.use(VueRouter);
export default new VueRouter({
  mode: 'hash',
  routes: [{
    path: '/',
    redirect: '/index',
    component: index
  }, {
    path: '/index',
    component: index
  }, {
    path: '/thzc',
    component: thzc
  }, {
    path: '/mjzc',
    component: mjzc
  }, {
    path: '/yszc',
    component: yszc
  }]
});