export default {
  message: {
    "msg1": "2015년에 탄생한 동남아시아 지역을 위한 맞춤형, 안전하고 빠른 온라인 쇼핑 플랫폼으로, 이 회사는 매우 선진적이고 호혜적인 비즈니스 모델을 계획했다.",
    "msg2": "동남아시아 선호 온라인 쇼핑 플랫폼",
    "msg3": "상점에 들어가다",
    "msg4": "선진적인 상업 전략",
    "msg5": "호혜호리의 모델을 채택하여 번영을 함께 창조하다.",
    "msg6": "동남아시아의 거대 소매업체",
    "msg7": "시장 선두주자, 소비자의 신뢰를 얻다.",
    "msg8": "글로벌 및 지역 브랜드 통합",
    "msg9": "국제와 본토의 원스톱 쇼핑 환경을 제공합니다.",
    "msg10": "정보",
    "msg11": "본사는 싱가포르에 있다.",
    "msg12": "현재 이미 동남아시아 시장에서 선두를 달리고 있는 온라인 쇼핑몰로, 많은 국제와 본토 브랜드의 원스톱 쇼핑 서비스를 제공한다.",
    "msg13": "우리 시장",
    "msg14": "현재 주요 영업 시장은 싱가포르, 말레이시아, 인도네시아, 태국, 베트남, 대만이다",
    "msg15": "2023년부터 일본 시장을 중점적으로 발전시킨다.",
    "msg16": "더 많은 지역, 기대해주세요",
    "msg17": "의 사명",
    "msg18": "글로벌 거래 플랫폼을 제공하여 누구나 그 안에서 어떤 물품도 거래할 수 있도록 하는 것이다.",
    "msg19": "개인 정보 보호 정책",
    "msg20": "소개",
    "msg21": "U-TESCO 스토어 웹 사이트 (\"U-TESCO\",\"우리\",\"우리\"또는\"우리\") 에 오신 것을 환영합니다.U-TESCO는 개인 정보 보호 법률 및 규정 (\"프라이버시 법규\") 적용에 따른 책임을 성실히 이행하고 있으며, U-TESCO 웹 사이트 및 모바일 애플리케이션 (\"플랫폼\") 의 모든 사용자에 대한 개인 정보 보호 및 우려 사항 (플랫폼과 플랫폼에서 설명하는 당사가 제공하는 서비스를 통칭하여 \"서비스\"라고 함) 을 존중할 것을 약속합니다.사용자는 구매자와 판매자 (\"사용자\", \"당신\" 또는 \"당신\") 를 포함하여 서비스를 사용하기 위해 계정에 등록한 사용자를 의미합니다.저희는 귀하가 저희에게 의뢰한 개인 데이터의 중요성을 인식하고 저희는 귀하의 개인 데이터를 적절하게 관리하고 보호하며 처리할 책임이 있다고 믿습니다.이 개인 정보 보호 정책 (\"개인 정보 보호 정책\" 또는 \"정책\") 은 귀하가 귀하에게 제공한 개인 데이터를 수집, 사용, 공개 및 처리하는 방법과 귀하가 보유한 개인 데이터, 현재나 미래, 그리고 귀하의 개인 데이터를 당사에 제공하기 전에 귀하가 현명한 결정을 내릴 수 있도록 돕는 것을 목적으로 합니다.",
    "msg22": "개인 데이터는 실제 여부와 관계없이 해당 데이터 또는 해당 데이터 및 조직에서 이미 또는 액세스 가능한 다른 정보로부터 식별할 수 있는 개인에 대한 데이터입니다.개인 데이터의 일반적인 예로는 이름, 주민등록번호, 연락처 정보가 있습니다.",
    "msg23": "서비스 사용, 계정 등록, 플랫폼 액세스 또는 서비스 액세스를 통해 귀하는 본 개인 정보 보호 정책에 명시된 관행, 요구 사항 및 정책에 동의하고 동의합니다.귀하는 여기에 명시된 대로 귀하의 개인 데이터를 수집, 사용, 공개 및 처리하는 데 동의합니다.귀하가 본 개인 정보 보호 정책에 명시된 대로 귀하의 개인 데이터를 처리하는 데 동의하지 않을 경우 Google 서비스를 사용하거나 Google 플랫폼에 액세스하지 마십시오.Google이 Google의 개인 정보 보호 정책을 변경하면 Google은 Google 플랫폼에 이러한 변경 사항이나 개정된 개인 정보 보호 정책을 게시하여 귀하에게 알립니다.저희는 언제든지 본 프라이버시 정책을 수정할 권리를 가집니다.해당 법률이 허용하는 최대 범위 내에서 주문을 포함하여 플랫폼의 서비스를 사용할 수 있습니다.",
    "msg24": "이 정책은 귀하의 개인 데이터를 수집, 저장, 사용, 공개 및 처리하는 데 적용되는 기타 통지, 계약 조항 및 동의 조항과 함께 적용됩니다. 명시적으로 명시되지 않는 한 이러한 통지 또는 조항을 번복할 의사가 없습니다.",
    "msg25": "달리 명시된 경우를 제외하고 본 정책은 본 서비스를 이용하는 매매 쌍방에게 적용된다.",
    "msg26": "U-TESCO는 언제 개인 데이터를 수집합니까?",
    "msg27": "저희는 귀하에 대한 개인 데이터를 수집할 것입니다.",
    "msg28": "귀하가 저희 사이트를 방문하고 방문할 때",
    "msg29": "귀하가 업무 신청서 또는 양식을 제출할 때, 신청서 양식이나 우리의 직위 발표와 관련된 기타 양식을 포함하되 이에 국한되지 않습니다.",
    "msg30": "전화 (녹음 가능), 메시지, 팩스, 대면 회의, 인터뷰, 소셜 미디어 플랫폼, 이메일 또는 웹 사이트를 통해 저희와 소통할 때.여기에는 귀하가 저희 사이트를 방문하고 방문할 때 저희가 배포할 수 있는 쿠키가 포함되지만 이에 국한되지 않습니다.",
    "msg31": "Google은 귀하, Google 관계자, 제3자 및 기타 출처에서 귀하에 대한 개인 데이터를 수집할 수 있습니다. 예를 들어 물류 또는 지불 서비스 제공업체, 신용 또는 평가 기관, 마케팅 서비스 제공업체 또는 파트너, 추천인 또는 충성도 계획, Google 서비스의 다른 사용자 또는 공개 사용 가능 또는 정부 데이터 소스를 포함하되 이에 국한되지 않습니다.",
    "msg32": "경우에 따라 가족 또는 친구 또는 연락처 목록에 있는 사람과 같은 다른 사람의 개인 데이터를 제공할 수 있습니다.귀하가 저희에게 그들의 개인 데이터를 제공할 경우 귀하는 귀하가 그들의 동의를 얻었고 본 정책에 따라 그들의 개인 데이터를 처리했다고 보증합니다.",
    "msg33": "U-TESCO는 어떤 개인 데이터를 수집합니까?",
    "msg34": "U-TESCO가 수집할 수 있는 개인 데이터에는 다음이 포함되지만 이에 국한되지는 않습니다.",
    "msg35": "이름;",
    "msg36": "이메일 주소",
    "msg37": "생년월일;",
    "msg38": "집 주소;",
    "msg39": "전화 번호;",
    "msg40": "귀하는 부정확하거나 오도적인 정보를 저희에게 제출하지 않는 것에 동의하고 귀하는 이러한 정보의 부정확하거나 변경된 사항을 저희에게 통지하는 것에 동의합니다.Google은 귀하가 제공한 모든 정보를 확인하기 위해 추가 파일을 요청할 권리를 보유합니다.",
    "msg41": "반품 정책",
    "msg42": "생각이 바뀌었어요",
    "msg43": "수거 또는 배송 후 15일 이내에 반품할 수 있습니다.",
    "msg44": "귀하의 아이템은 다음이어야 합니다:",
    "msg45": "사용되지 않고 모든 원본 어셈블리가 있습니다.",
    "msg46": "원본 포장 (해당되는 경우 레이블 포함)",
    "msg47": "제품 지원",
    "msg48": "대부분의 경우 물품 문제가 있으면 집에서 편안하게 제품 지원 웹 사이트를 확인하여 문제를 해결할 수 있는지 확인할 수 있습니다.그곳에서 귀하는 제품과 우리 회사에 관한 자주 묻는 질문과 대답, 운송과 수령, 반품과 환불을 방문할 수 있습니다.",
    "msg49": "내 화물에 문제가 있다",
    "msg50": "귀하가 여전히 반품이 필요한 경우 (이전 15일 이내) 저희는 귀하께 교환, 교환 또는 환불을 제공할 것입니다.",
    "msg51": "이 기간 이후에도 무상수리 기간 동안 Dell은 전문 수리 담당자를 사용하여 수리를 예약하거나 수리할 수 없는 경우 제품을 교체하거나 교체합니다.가장 빠른 반품 방법은 112개국에 위치한 글로벌 창고 센터를 통해서입니다.처음에는 집까지 배달되었더라도 큰 물건을 제외한 대부분의 물건을 받아들일 수 있습니다.가장 가까운 상점을 찾으십시오.큰 상품을 반품하려면 아래로 스크롤하여 실시간 채팅을 시작하거나 도움말 핫라인 링크로 이동하십시오.",
    "msg52": "배송 및 수거",
    "msg53": "당신은 어디에서 물건을 배달합니까?",
    "msg54": "귀하의 지역으로 배송되는지 확인하려면 구매하려는 품목에 우편 번호를 입력하십시오.제품은 공급업체에서 직접 배송되며 전 세계 112개국에 걸쳐 보관됩니다.",
    "msg55": "어디 가서 찾지?",
    "msg56": "저희는 U-TESCO 스토어와 글로벌 창고의 수집 지점에서 연중무휴 24시간 운영됩니다.",
    "msg57": "신속 통로 납품에 사용할 수 있는 물품의 경우 귀하의 위치에 따라 여러 시간대 중에서 선택하십시오.주문은 집까지 배송되어 안전하고 신뢰할 수 있는 곳에 보관됩니다.",
    "msg58": "자주 묻는 질문",
    "msg59": "만약 제가 큰 상품과 작은 상품을 동시에 주문하면 어떻게 합니까?",
    "msg60": "가장 빠른 방법은 개별적으로 배송하는 것입니다.걱정하지 마십시오.배송비는 한 번만 지불하시면 됩니다.자세한 내용은 주문 확인 이메일을 참조하십시오.",
    "msg61": "내가 집에 없으면 어떡하지?",
    "msg62": "작은 물건은 안전하고 신뢰할 수있는 장소, 보이지 않는 장소 또는 귀하의 요청에 따라 이웃에게 전달됩니다.큰 물건이나 귀중품은 사진이나 서명이 필요하며 남겨둘 수 없기 때문에 반드시 집에서 수령하는 사람이 있어야 한다.도착하지 못할 경우 배송 일정을 재조정하고 가장 적합한 날짜와 기간을 선택할 수 있도록 헬프 데스크를 통해 문의해 주십시오!",
    "msg63": "고객 서비스",
    "msg64": "온라인 고객 서비스",
    "msg65": "App 다운로드(구매자)",
    "msg66": "App 다운로드(판매자)",
    "msg67": "반품 및 교환",
    "msg68": "개인 정보 보호 정책",
    "msg69": "반품 정책",
    "msg70": "배송 및 수거",
    "msg71": "사용자 센터",
    "msg72": "사용자 등록",
    "msg73": "상가 로그인",
    "msg74": "사용자 프로토콜",
    "msg75": "우리에 대해",
    "msg76": "현인을 초빙하다.",
    "msg77": "현대 노예 성명"
  }
};