export default {
  message: {
    msg1: 'は 2015 年に誕生し、東南アジアに特化した安全かつ迅速なオンライン ショッピング プラットフォームであり、非常に先進的でお互い利益を得るビジネスモデルです。',
    msg2: '東南アジアで人気のオンライン ショッピング プラットフォーム',
    msg3: 'ストアに入る',
    msg4: '先進的な事業戦略',
    msg5: '相互に利益をもたらすモデルを採用し、共に繁栄を築きましょう。',
    msg6: '東南アジアの小売大手',
    msg7: 'マーケットリーダーは消費者の信頼を勝ち取ります。',
    msg8: 'グローバルブランドとローカルブランドが集結',
    msg9: '国内外のワンストップショッピング体験を提供します。',
    msg10: 'について',
    msg11: 'はシンガポールに本社を置いています。',
    msg12: 'は現在、東南アジアを代表するオンラインショッピングモールであり、多くの国際ブランドや地元ブランドにワンストップ ショッピング サービスを提供しています。',
    msg13: '私たちの市場',
    msg14: '現在の主力市場はシンガポール、マレーシア、インドネシア、タイ、ベトナム、台湾、中国で',
    msg15: '2023年には日本市場の開拓に注力しています。',
    msg16: 'さらに多くのエリアをご覧ください',
    msg17: 'の使命は',
    msg18: '誰もがあらゆるアイテムを取引できるグローバル取引プラットフォームを提供することです。',
    zhHans: '中文简体',
    en: 'English',
    fr: 'Français',
    ko: '한국어',
    ja: '日本語',
    zhHant: '中文繁体',
    de: 'Deutsch',
    es: 'Español',
    ru: 'Русский язык',
    th: 'ภาษาไทย',
    pt: 'Português',
    vnm: 'Tiếng Việt',
    "msg19": "プライバシーポリシー",
    "msg20": "概要",
    "msg21": "U-TESCOストアのウェブサイト（単独および総称して「U-TESCO」、「私たち」、「私たち」または「私たち」）へようこそ。U-TESCOはプライバシー法（「プライバシー法」）の規定を適用する責任を真剣に履行し、U-TESCOウェブサイトとモバイルアプリケーション（「プラットフォーム」）のすべてのユーザーのプライバシー権と懸念（プラットフォームとプラットフォーム上で説明した私たちが提供するサービスを総称して「サービス」と呼ぶ）を尊重することを約束した。ユーザーとは、サービスを使用するためにアカウントを登録してくれたユーザーのことで、購入者や売り手（個別に、総称して「ユーザー」、「あなた」、または「あなた」）を含みます。私たちはあなたが私たちに依頼した個人データの重要性を認識し、私たちにはあなたの個人データを適切に管理、保護、処理する責任があると信じています。このプライバシーポリシー（「プライバシーポリシー」または「ポリシー」）は、お客様が提供してくれた個人データの収集、使用、開示、処理方法、およびお客様が所有している個人データについて、今も将来も、お客様の個人データを提供する前に賢明な決定を支援することを目的としています。",
    "msg22": "個人データとは、実際であるかどうかにかかわらず、データや組織がすでにアクセス可能であるか、またはアクセス可能である可能性のある他の情報から識別できる個人に関するデータを指します。個人データの一般的な例には、名前、身分証明書番号、連絡先情報が含まれます。",
    "msg23": "サービスの使用、アカウントの登録、プラットフォームへのアクセス、またはサービスへのアクセスを通じて、プライバシーポリシーの概要に記載されているアプローチ、要件、ポリシーを受け入れることを承認し、同意します。ここでは、ここに記載されているように、個人データを収集、使用、開示、処理することに同意します。このプライバシーポリシーの説明に従って個人データを処理することに同意しない場合は、サービスを使用したり、プラットフォームにアクセスしたりしないでください。プライバシーポリシーを変更する場合は、変更または修正されたプライバシーポリシーをプラットフォームに公開することでお知らせします。デルは、このプライバシーポリシーを随時変更する権利を保持しています。法的に許容される最大の範囲内で、注文を含むプラットフォーム上のサービスを使用できます。",
    "msg24": "このポリシーは、お客様の個人データを収集、保存、使用、開示、処理するのに適したその他の通知、契約条件、同意条項とともに適用されます。特に明確な説明がない限り、これらの通知または条項を覆すことはありません。",
    "msg25": "別途明確な規定があるほか、本政策は本サービスを利用する売買双方に適用される。",
    "msg26": "U-TESCOはいつ個人データを収集しますか。",
    "msg27": "デルは、お客様の個人データを収集するかもしれません。",
    "msg28": "私たちのウェブサイトにアクセスしてアクセスすると、",
    "msg29": "作業申請書またはフォームを提出する際には、申請フォームまたは私たちの任意の職階の発行に関連する他のフォームが含まれますが、これらに限定されません。",
    "msg30": "電話（録音される場合があります）、手紙、FAX、対面会議、インタビュー、ソーシャルメディアプラットフォーム、Eメール、またはWebサイトを介して私たちとやり取りするとき。これには、Webサイトにアクセスしてアクセスしたときに導入することができるクッキーが含まれますが、これらに限定されません。",
    "msg31": "デルは、物流や支払サービスプロバイダなどのビジネスパートナー、信用や格付け機関、マーケティングサービスプロバイダやパートナー、推薦者やロイヤルティプログラム、デルのサービスの他のユーザー、または利用可能なデータソースや政府のデータソースを含むがこれらに限定されない、お客様、デルの関連先、サードパーティ、その他のソースから個人データを収集することがあります。",
    "msg32": "場合によっては、家族や友人、連絡先リストの人など、他の人の個人データを提供してくれることがあります。もしあなたが私たちに個人データを提供するならば、あなたはあなたが彼らの同意を得て、本政策に基づいて彼らの個人データを処理することを声明して保証します。",
    "msg33": "U-TESCOはどのような個人データを収集していますか。",
    "msg34": "U-TESCOが収集する可能性のある個人データには、以下のものが含まれるが、これらに限定されない：",
    "msg35": "名前",
    "msg36": "電子メールアドレス",
    "msg37": "誕生日",
    "msg38": "住所：",
    "msg39": "電話番号",
    "msg40": "あなたは私たちに正確ではない、あるいは誤解された情報を提出しないことに同意して、そしてあなたはこのような情報のいかなる不正確さや変更を私たちに通知することに同意します。私たちは、提供された情報を検証するためのさらなるファイルの提供を要求する権利を保持します。",
    "msg41": "返品ポリシー",
    "msg42": "考えが変わった",
    "msg43": "返品は、商品を受け取ってから15日以内に行うことができます。",
    "msg44": "あなたのアイテムは次のものでなければなりません。",
    "msg45": "未使用で元のコンポーネントがすべてある、",
    "msg46": "オリジナルパッケージ（該当する場合はラベル付き）、",
    "msg47": "製品サポート",
    "msg48": "ほとんどの場合、何か問題があれば、自宅で快適に製品サポートサイトを見て、問題を解決できるかどうかを確認することができます。そこで、製品や当社に関するFAQ、配送、返品、返金にアクセスできます。",
    "msg49": "私の荷物に問題がある",
    "msg50": "返品が必要な場合（前の15日間）は、交換、交換、または返金を行います。",
    "msg51": "この期間が経過しても、保証期間内であれば、デルの専門修理代理店を使用して修理を手配します。または、修理できない場合は、お客様の商品を交換または交換します。最速の返品方法は、112の国にあるグローバル倉庫センターを通じて行われます。最初は宅配便であっても、大きなもの以外のほとんどのものを受け入れることができます。最寄りの店を見つけます。大きな商品を返品する場合は、下にスクロールしてリアルタイムチャットを開始するか、ヘルプホットラインへのリンクを開始してください。",
    "msg52": "配送及び引取",
    "msg53": "どこで配達しますか。",
    "msg54": "お客様の地域に配送するかどうかを確認するには、購入する商品に郵便番号を入力してください。製品はサプライヤから直接出荷され、世界中の倉庫は112カ国に広がっています。",
    "msg55": "どこで取りに行きますか。",
    "msg56": "私たちはU-TESCOストアとグローバル倉庫の収集拠点で24/7にわたって運営しています。",
    "msg57": "ファスト・トラック納品に使用できるアイテムは、場所に応じて複数の期間から選択してください。注文書は宅配され、安全で信頼できる場所に保管されます。",
    "msg58": "よくある質問",
    "msg59": "もし私が大きな商品と小さな商品を同時に注文したらどうしますか。",
    "msg60": "それらをあなたの手に届ける最も速い方法は単独で輸送することです。心配しないで送料は1回だけお支払いください。詳細については、注文確認Eメールを参照してください。",
    "msg61": "もし私が家にいなかったらどうする？",
    "msg62": "小物は安全で信頼性の高い場所、見えない場所、またはあなたの要求に応じて隣人に渡します。大きなものや貴重品は写真やサインが必要で残してはいけないので、誰かが家で受け取る必要があります。もしあなたが到着できない場合は、ヘルプホットラインを通じて私たちに連絡して、私たちが配達時間を再手配して、あなたに最適な日付と時間帯を選択するために！",
    "msg63": "カスタマーサービス",
    "msg64": "オンラインカスタマーサービス",
    "msg65": "アプリのダウンロード (バイヤー側)",
    "msg66": "アプリのダウンロード (販売者側)",
    "msg67": "返品と交換",
    "msg68": "プライバシーポリシー",
    "msg69": "返品ポリシー",
    "msg70": "配送及び引取",
    "msg71": "ユーザーセンター",
    "msg72": "ユーザー登録",
    "msg73": "事業者登録",
    "msg74": "ユーザプロトコル",
    "msg75": "私たちについて",
    "msg76": "賢者を招く",
    "msg77": "現代奴隷声明"
  }
};