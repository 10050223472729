export default {
  message: {
    "msg1": "Ra đời vào năm 2015, là một nền tảng mua sắm trực tuyến an toàn và nhanh chóng phù hợp với khu vực Đông Nam Á, công ty đã lên kế hoạch cho một mô hình kinh doanh rất tiên tiến và cùng có lợi.",
    "msg2": "Nền tảng mua sắm trực tuyến hàng đầu Đông Nam Á",
    "msg3": "Vào Mall",
    "msg4": "Chiến lược kinh doanh tiên tiến",
    "msg5": "Tiếp thu mô hình cùng có lợi, cùng sáng tạo phồn vinh.",
    "msg6": "Người khổng lồ bán lẻ ở Đông Nam Á",
    "msg7": "Người dẫn đầu thị trường, giành được sự tin tưởng của người tiêu dùng.",
    "msg8": "Hội tụ thương hiệu toàn cầu và địa phương",
    "msg9": "Cung cấp cho bạn trải nghiệm mua sắm một cửa quốc tế và địa phương.",
    "msg10": "Giới thiệu",
    "msg11": "Trụ sở chính đặt tại Singapore.",
    "msg12": "Hiện là trung tâm mua sắm trực tuyến hàng đầu tại thị trường Đông Nam Á, cung cấp dịch vụ mua sắm một cửa với nhiều thương hiệu quốc tế và địa phương.",
    "msg13": "Thị trường của chúng tôi",
    "msg14": "Thị trường chính hiện nay là Singapore, Malaysia, Indonesia, Thái Lan, Việt Nam, Trung Quốc Đài Loan",
    "msg15": "2023 bắt đầu tập trung phát triển thị trường Nhật Bản.",
    "msg16": "Nhiều khu vực hơn, xin vui lòng chờ đợi",
    "msg17": "Sứ mệnh",
    "msg18": "Đó là cung cấp một nền tảng giao dịch toàn cầu, nơi bất cứ ai cũng có thể giao dịch bất cứ thứ gì.",
    "msg19": "Chính sách bảo mật",
    "msg20": "Giới thiệu",
    "msg21": "Chào mừng bạn đến với trang web Cửa hàng U-TESCO (gọi riêng và chung là \"U-TESCO\", \"chúng tôi\", \"của chúng tôi\" hoặc \"của chúng tôi\"). U-TESCO nghiêm túc thực hiện trách nhiệm của mình theo luật và quy định về quyền riêng tư hiện hành (\"Đạo luật về quyền riêng tư\") và cam kết tôn trọng quyền riêng tư và mối quan tâm của tất cả người dùng của chúng tôi, các trang web và ứng dụng di động của U-TESCO (\"Nền tảng\") (chúng tôi gọi chung Nền tảng và các dịch vụ mà chúng tôi cung cấp như được mô tả trên Nền tảng là \"Dịch vụ\"). Người dùng là người dùng đã đăng ký tài khoản với chúng tôi để sử dụng Dịch vụ, bao gồm cả người mua và người bán (gọi riêng và chung là \"Người dùng\", \"Bạn\" hoặc \"Của Bạn\"). Chúng tôi nhận ra tầm quan trọng của dữ liệu cá nhân mà bạn ủy thác cho chúng tôi và tin rằng chúng tôi có trách nhiệm quản lý, bảo vệ và xử lý dữ liệu cá nhân của bạn đúng cách. Chính sách bảo mật này (\"Chính sách bảo mật\" hoặc \"Chính sách\") được thiết kế để giúp bạn hiểu cách chúng tôi thu thập, sử dụng, tiết lộ và xử lý dữ liệu cá nhân mà bạn cung cấp cho chúng tôi và chúng tôi có về bạn, hiện tại và trong tương lai, và để giúp bạn đưa ra quyết định sáng suốt trước khi cung cấp cho chúng tôi bất kỳ dữ liệu cá nhân nào của bạn.",
    "msg22": "Dữ liệu cá nhân là dữ liệu về các cá nhân có thể được xác định từ dữ liệu đó hoặc từ dữ liệu đó và các thông tin khác mà tổ chức đã hoặc có thể truy cập, có xác thực hay không. Ví dụ phổ biến về dữ liệu cá nhân bao gồm tên, số ID và thông tin liên lạc.",
    "msg23": "Bằng cách sử dụng Dịch vụ, đăng ký tài khoản với chúng tôi, truy cập Nền tảng của chúng tôi hoặc truy cập Dịch vụ, bạn thừa nhận và đồng ý rằng bạn chấp nhận các thực tiễn, yêu cầu và chính sách được nêu trong Chính sách bảo mật này. Bạn đồng ý với việc chúng tôi thu thập, sử dụng, tiết lộ và xử lý dữ liệu cá nhân của bạn như được mô tả ở đây. Nếu bạn không đồng ý với việc xử lý dữ liệu cá nhân của mình như được mô tả trong Chính sách bảo mật này, vui lòng không sử dụng Dịch vụ của chúng tôi hoặc truy cập Nền tảng của chúng tôi. Nếu chúng tôi thay đổi Chính sách bảo mật của mình, chúng tôi sẽ thông báo cho bạn bằng cách đăng những thay đổi này hoặc Chính sách bảo mật sửa đổi trên nền tảng của chúng tôi. Chúng tôi bảo lưu quyền sửa đổi Chính sách bảo mật này bất cứ lúc nào. Trong phạm vi tối đa cho phép theo luật hiện hành, bạn có thể sử dụng các dịch vụ trên nền tảng, bao gồm đặt hàng.",
    "msg24": "Chính sách này áp dụng cùng với các thông báo, điều khoản hợp đồng và điều khoản đồng ý khác áp dụng cho việc thu thập, lưu trữ, sử dụng, tiết lộ và xử lý dữ liệu cá nhân của bạn và không có ý định đảo ngược các thông báo hoặc điều khoản này trừ khi chúng tôi nêu rõ khác.",
    "msg25": "Trừ khi có quy định rõ ràng khác, chính sách này áp dụng cho người mua và người bán sử dụng Dịch vụ.",
    "msg26": "Khi nào U-TESCO thu thập dữ liệu cá nhân?",
    "msg27": "Chúng tôi sẽ/có thể thu thập dữ liệu cá nhân về bạn:",
    "msg28": "Khi bạn truy cập và truy cập trang web của chúng tôi;",
    "msg29": "Khi bạn nộp bất kỳ đơn xin việc hoặc mẫu đơn nào, bao gồm nhưng không giới hạn ở mẫu đơn hoặc mẫu đơn khác liên quan đến việc phát hành bất kỳ vị trí nào của chúng tôi;",
    "msg30": "Khi bạn tương tác với chúng tôi qua điện thoại (có thể được ghi âm), thư từ, fax, gặp mặt trực tiếp, phỏng vấn, nền tảng truyền thông xã hội, email hoặc thông qua trang web. Nó bao gồm nhưng không giới hạn ở các cookie mà chúng tôi có thể triển khai khi bạn truy cập và truy cập trang web của chúng tôi;",
    "msg31": "Chúng tôi có thể thu thập dữ liệu cá nhân về bạn từ bạn, các bên liên kết, bên thứ ba và các nguồn khác, bao gồm nhưng không giới hạn đối tác kinh doanh (ví dụ: nhà cung cấp dịch vụ hậu cần hoặc thanh toán), cơ quan tín dụng hoặc xếp hạng, nhà cung cấp dịch vụ tiếp thị hoặc đối tác, người giới thiệu hoặc chương trình khách hàng thân thiết, người dùng khác của Dịch vụ của chúng tôi hoặc nguồn dữ liệu công khai hoặc chính phủ.",
    "msg32": "Trong một số trường hợp, bạn có thể cung cấp cho chúng tôi dữ liệu cá nhân của người khác (chẳng hạn như gia đình hoặc bạn bè của bạn hoặc những người trong danh sách liên hệ của bạn). Nếu bạn cung cấp cho chúng tôi dữ liệu cá nhân của họ, bạn đại diện và đảm bảo rằng bạn đã nhận được sự đồng ý của họ để xử lý dữ liệu cá nhân của họ theo Chính sách này.",
    "msg33": "U-TESCO thu thập dữ liệu cá nhân nào?",
    "msg34": "Dữ liệu cá nhân mà U-TESCO có thể thu thập bao gồm, nhưng không giới hạn ở:",
    "msg35": "Tên;",
    "msg36": "Địa chỉ email;",
    "msg37": "Ngày sinh;",
    "msg38": "Địa chỉ nhà;",
    "msg39": "Số điện thoại;",
    "msg40": "Bạn đồng ý không gửi cho chúng tôi bất kỳ thông tin không chính xác hoặc gây hiểu lầm nào và bạn đồng ý thông báo cho chúng tôi về bất kỳ sự không chính xác hoặc thay đổi nào trong thông tin đó. Chúng tôi có quyền yêu cầu thêm tài liệu để xác minh bất kỳ thông tin nào bạn cung cấp.",
    "msg41": "Chính sách hoàn trả",
    "msg42": "Tôi đổi ý rồi.",
    "msg43": "Bạn có thể trả lại hàng trong vòng 15 ngày sau khi nhận hoặc giao hàng.",
    "msg44": "Mục của bạn phải là:",
    "msg45": "Không sử dụng và có tất cả các thành phần ban đầu;",
    "msg46": "trong bao bì gốc (nếu có nhãn);",
    "msg47": "Hỗ trợ sản phẩm",
    "msg48": "Trong hầu hết các trường hợp, nếu bạn có bất kỳ vấn đề nào về mặt hàng, bạn có thể kiểm tra trang web hỗ trợ sản phẩm của chúng tôi từ sự thoải mái của nhà bạn để xem liệu chúng tôi có thể giải quyết vấn đề hay không. Ở đó, bạn có thể truy cập các câu hỏi thường gặp về sản phẩm và công ty chúng tôi, vận chuyển và nhận hàng, trả lại và hoàn tiền.",
    "msg49": "Hàng của tôi có vấn đề.",
    "msg50": "Nếu bạn vẫn cần trả lại hàng (trong vòng 15 ngày đầu tiên), chúng tôi sẽ cung cấp cho bạn một sự trao đổi, thay thế hoặc hoàn lại tiền.",
    "msg51": "Sau thời hạn này, chúng tôi sẽ sử dụng đại lý sửa chữa chuyên nghiệp của chúng tôi để sắp xếp sửa chữa miễn là sản phẩm vẫn còn trong thời hạn bảo hành hoặc, nếu không thể sửa chữa, thay thế hoặc thay thế hàng hóa của bạn. Cách nhanh nhất để trả lại hàng là thông qua các trung tâm kho bãi toàn cầu của chúng tôi tại 112 quốc gia. Chúng tôi có thể chấp nhận hầu hết các mặt hàng ngoại trừ các mặt hàng lớn, ngay cả khi chúng được giao ngay từ đầu. Tìm cửa hàng gần nhất với bạn. Nếu bạn đang trả lại một món hàng lớn, hãy cuộn xuống để bắt đầu trò chuyện trực tiếp hoặc truy cập đường dây trợ giúp của chúng tôi.",
    "msg52": "Giao hàng&Nhận hàng",
    "msg53": "Anh giao hàng ở đâu?",
    "msg54": "Để tìm hiểu xem chúng tôi vận chuyển đến khu vực của bạn, vui lòng nhập mã zip của bạn trên mặt hàng bạn muốn mua. Sản phẩm được vận chuyển trực tiếp từ các nhà cung cấp của chúng tôi và kho hàng toàn cầu của chúng tôi trải rộng trên 112 quốc gia.",
    "msg55": "Lấy ở đâu?",
    "msg56": "Chúng tôi hoạt động 24/7 tại các điểm thu gom tại các cửa hàng U-TESCO và các kho hàng trên toàn thế giới.",
    "msg57": "Đối với các mặt hàng có sẵn để giao hàng nhanh, hãy chọn từ nhiều khoảng thời gian dựa trên vị trí của bạn. Đơn đặt hàng sẽ được giao tận nhà và lưu trữ ở nơi an toàn và an toàn.",
    "msg58": "Câu hỏi thường gặp",
    "msg59": "Điều gì sẽ xảy ra nếu tôi đặt hàng các mặt hàng lớn và nhỏ cùng một lúc?",
    "msg60": "Cách nhanh nhất để đưa chúng đến tay bạn là vận chuyển riêng lẻ. Không cần lo lắng. Bạn chỉ phải trả tiền vận chuyển một lần. Vui lòng kiểm tra email xác nhận đặt hàng của bạn để biết thêm thông tin.",
    "msg61": "Nếu tôi không ở nhà thì sao?",
    "msg62": "Các vật dụng nhỏ sẽ được để lại ở một nơi an toàn và an toàn, ngoài tầm nhìn hoặc giao cho hàng xóm của bạn theo yêu cầu của bạn. Đồ vật lớn hoặc có giá trị cần có ảnh hoặc chữ ký và không thể để lại, vì vậy ai đó phải lấy chúng ở nhà. Nếu bạn không thể đến đó, vui lòng liên hệ với chúng tôi qua đường dây trợ giúp để chúng tôi có thể sắp xếp lại thời gian giao hàng và chọn ngày và thời gian phù hợp nhất với bạn!",
    "msg63": "Dịch vụ khách hàng",
    "msg64": "Hỗ trợ trực tuyến",
    "msg65": "Download app (người mua)",
    "msg66": "Download app (bán hàng)",
    "msg67": "Trả lại và trao đổi",
    "msg68": "Chính sách bảo mật",
    "msg69": "Chính sách hoàn trả",
    "msg70": "Giao hàng&Nhận hàng",
    "msg71": "Trung tâm người dùng",
    "msg72": "Đăng ký thành viên",
    "msg73": "Đăng nhập thương gia",
    "msg74": "Thỏa thuận người dùng",
    "msg75": "Về chúng tôi",
    "msg76": "Tuyển hiền nạp sĩ",
    "msg77": "Lời bài hát: Modern Slave"
  }
};