var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "h-title" }, [
      _vm._v(_vm._s(_vm.$t("message.msg19")))
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", [
        _c("div", { staticClass: "title" }, [
          _vm._v("1、" + _vm._s(_vm.$t("message.msg20")))
        ]),
        _c("p", [
          _vm._v(
            " 1.1 " + _vm._s(_vm.$t("message.msg21")) + "\n                "
          )
        ]),
        _c("p", [_vm._v(" 1.2 " + _vm._s(_vm.$t("message.msg22")))]),
        _c("p", [_vm._v(" 1.3 " + _vm._s(_vm.$t("message.msg23")))]),
        _c("p", [_vm._v(" 1.4 " + _vm._s(_vm.$t("message.msg24")))]),
        _c("p", [_vm._v(" 1.5 " + _vm._s(_vm.$t("message.msg25")))])
      ]),
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v("2、 " + _vm._s(_vm.$t("message.msg26")))
        ]),
        _c("p", [_vm._v("2.1. " + _vm._s(_vm.$t("message.msg27")))]),
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg28")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg29")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg30")))])
        ]),
        _c("p", [_vm._v(" 2.2 " + _vm._s(_vm.$t("message.msg31")))]),
        _c("p", [_vm._v(" 2.3 " + _vm._s(_vm.$t("message.msg32")))])
      ]),
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v("3、 " + _vm._s(_vm.$t("message.msg33")))
        ]),
        _c("p", [_vm._v("3.1. " + _vm._s(_vm.$t("message.msg34")))]),
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg35")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg36")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg37")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg38")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("message.msg39")))])
        ]),
        _c("p", [_vm._v(" 3.2 " + _vm._s(_vm.$t("message.msg40")))])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-nav-component w-nav",
        attrs: {
          "data-animation": "default",
          "data-collapse": "medium",
          "data-duration": "400",
          "data-easing": "ease",
          "data-easing2": "ease",
          role: "banner"
        }
      },
      [
        _c("div", { staticClass: "avatarkit-container-component" }, [
          _c("div", { staticClass: "avatar-navbar-global-padding-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "avatarkit-navbar-brand w-nav-brand",
                attrs: { href: "/" }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/logo.png"),
                    loading: "lazy",
                    width: "63",
                    alt: ""
                  }
                })
              ]
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: require("../assets/images/header-img-home-01.jpg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }