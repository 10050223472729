export default {
  message: {
    "msg1": "Née en 2015 en tant que plate - forme d'achat en ligne sécurisée et rapide sur mesure pour la région de l'Asie du Sud - Est, la société a planifié un modèle d'affaires très avancé et mutuellement bénéfique.",
    "msg2": "Plateforme d'achat en ligne préférée en Asie du Sud - Est",
    "msg3": "Accès au centre commercial",
    "msg4": "Stratégie commerciale avancée",
    "msg5": "Adopter un modèle mutuellement bénéfique pour créer la prospérité.",
    "msg6": "Les géants de la vente au détail en Asie du Sud - Est",
    "msg7": "Leader du marché, gagner la confiance des consommateurs.",
    "msg8": "Convergence des marques mondiales et locales",
    "msg9": "Offrez - vous une expérience de shopping unique à la fois internationale et locale.",
    "msg10": "À propos",
    "msg11": "Basée à Singapour.",
    "msg12": "Maintenant le premier centre commercial de shopping en ligne en Asie du Sud - Est, il offre un guichet unique pour de nombreuses marques internationales et locales.",
    "msg13": "Nos marchés",
    "msg14": "Actuellement, le marché principal est Singapour, Malaisie, Indonésie, Thaïlande, Vietnam, Chine Taiwan",
    "msg15": "2023 commence à se concentrer sur le développement du marché japonais.",
    "msg16": "Plus de régions, attendez - vous à",
    "msg17": "La Mission",
    "msg18": "Il s'agit de fournir une plate - forme de Trading mondiale où n'importe qui peut échanger n'importe quel article.",
    "msg19": "Politique de confidentialité",
    "msg20": "Introduction",
    "msg21": "Bienvenue sur le site Web de la boutique U - Tesco (individuellement et collectivement, « U - Tesco », « nous », « Notre » ou « nos »). U - Tesco prend au sérieux ses responsabilités en vertu des lois et règlements applicables en matière de protection de la vie privée (« lois sur la protection de la vie privée ») et s’engage à respecter nos droits et préoccupations en matière de protection de la vie privée, ainsi que ceux de tous les utilisateurs des sites Web et applications mobiles U - Tesco (« plateforme ») (nous désignons collectivement la plateforme et les services que nous fournissons décrits sur la plateforme comme étant les « services »). Un utilisateur est un utilisateur, y compris un acheteur et un vendeur (individuellement et collectivement, « utilisateur », « vous » ou « votre ») qui s’inscrit à un compte avec nous pour utiliser les services. Nous reconnaissons l’importance des données personnelles que vous nous confiez et nous croyons qu’il est de notre responsabilité de bien gérer, protéger et traiter vos données personnelles. La présente politique de confidentialité (la « politique de confidentialité » ou la « politique ») est conçue pour vous aider à comprendre comment nous recueillons, utilisons, divulguons et traitons les données personnelles que vous nous fournissez et que nous détenons à votre sujet, maintenant et à l’avenir, et pour vous aider à prendre des décisions éclairées avant de nous fournir vos données personnelles.",
    "msg22": "Les données personnelles sont des données, véridiques ou non, concernant une personne qui peut être identifiée à partir de ces données ou à partir de ces données et d’autres informations auxquelles une organisation a ou peut avoir accès. Des exemples courants de données personnelles comprennent le nom, le numéro d'identification et les coordonnées.",
    "msg23": "En utilisant les services, en créant un compte avec nous, en accédant à notre plateforme ou en accédant aux services, vous reconnaissez et acceptez que vous acceptez les pratiques, les exigences et les politiques décrites dans la présente politique de confidentialité. Par la présente, vous consentez à ce que nous collections, utilisions, divulguions et traitions vos données personnelles comme décrit ici. Si vous ne consentez pas au traitement de vos données personnelles tel que décrit dans la présente politique de confidentialité, veuillez ne pas utiliser nos services ou accéder à notre plateforme. Si nous modifions notre politique de confidentialité, nous vous en informerons en publiant ces politiques de confidentialité modifiées ou révisées sur notre plateforme. Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Dans toute la mesure permise par la loi applicable, vous pouvez utiliser les services sur la plateforme, y compris passer des commandes.",
    "msg24": "Cette politique s’applique conjointement avec d’autres avis, conditions contractuelles et conditions de consentement applicables à notre collecte, stockage, utilisation, divulgation et traitement de vos données personnelles, qui ne sont pas destinés à être annulés, sauf indication contraire expresse de notre part.",
    "msg25": "Sauf indication contraire expresse, cette politique s'applique aux acheteurs et aux vendeurs qui utilisent le service.",
    "msg26": "Quand U - Tesco collecte - t - elle des données personnelles?",
    "msg27": "Nous collecterons / pouvons collecter des données personnelles vous concernant:",
    "msg28": "Lorsque vous visitez et accédez à notre site Web;",
    "msg29": "Lorsque vous soumettez une demande d’emploi ou un formulaire, y compris, mais sans s’y limiter, un formulaire de demande ou tout autre formulaire lié à l’une de nos offres d’emploi;",
    "msg30": "Lorsque vous interagissez avec nous par téléphone (qui peut être enregistré), lettre, fax, réunions en personne, interviews, plateformes de médias sociaux, e - mails ou via le site Web. Cela inclut, mais sans s’y limiter, les cookies que nous pouvons déployer lorsque vous visitez et visitez notre site Web;",
    "msg31": "Nous pouvons collecter des données personnelles vous concernant auprès de vous, de nos sociétés affiliées, de tiers et d’autres sources, y compris, mais sans s’y limiter, des partenaires commerciaux (tels que des prestataires de services logistiques ou de paiement), des agences de crédit ou de notation, des fournisseurs ou partenaires de services marketing, des référents ou des programmes de fidélité, d’autres utilisateurs de nos services ou des sources de données publiques ou gouvernementales.",
    "msg32": "Dans certains cas, vous pouvez nous fournir des données personnelles sur d'autres personnes (par exemple, votre famille ou vos amis ou des personnes figurant sur votre liste de contacts). Si vous nous fournissez leurs données personnelles, vous déclarez et garantissez que vous avez obtenu leur consentement pour traiter leurs données personnelles conformément à la présente politique.",
    "msg33": "Quelles données personnelles sont collectées par u - Tesco?",
    "msg34": "Les données personnelles que U - Tesco peut collecter incluent, mais ne sont pas limitées à:",
    "msg35": "Nom et prénom;",
    "msg36": "Adresse électronique;",
    "msg37": "Date de naissance;",
    "msg38": "Adresse du domicile;",
    "msg39": "Numéro de téléphone;",
    "msg40": "Vous acceptez de ne pas nous soumettre d'informations inexactes ou trompeuses et vous acceptez de nous informer de toute inexactitude ou modification de ces informations. Nous nous réservons le droit de demander des documents supplémentaires pour vérifier toute information que vous avez fournie.",
    "msg41": "Politique de retour",
    "msg42": "J'ai changé d'avis",
    "msg43": "Vous pouvez retourner un article dans les 15 jours suivant la collecte ou la livraison.",
    "msg44": "Votre article doit être:",
    "msg45": "Non utilisé et avec tous les composants d'origine;",
    "msg46": "Dans l'emballage d'origine (avec étiquette, le cas échéant);",
    "msg47": "Support produit",
    "msg48": "Dans la plupart des cas, si vous avez des problèmes avec des articles, vous pouvez consulter notre site de support produit dans le confort de votre maison pour voir si nous pouvons résoudre le problème. Là, vous pouvez accéder aux FAQ sur les produits et notre société, expédition et retrait, retours et remboursements.",
    "msg49": "J'ai un problème avec ma cargaison",
    "msg50": "Si vous avez encore besoin de retourner un article (dans les 15 premiers jours), nous vous proposerons un échange, un remplacement ou un remboursement.",
    "msg51": "Après cette période, tant que le produit est toujours sous garantie, nous utiliserons nos agents de réparation professionnels pour organiser la réparation ou, si la réparation n'est pas possible, pour remplacer ou remplacer votre article. Le moyen le plus rapide de retourner un article est via nos centres de stockage mondiaux situés dans 112 pays. Nous pouvons accepter la plupart des articles, à l'exception des articles volumineux, même s'ils sont initialement livrés à domicile. Trouvez le magasin le plus proche de chez vous. Si vous retournez un article volumineux, faites défiler vers le bas pour commencer le chat en direct ou rendez - vous sur notre lien d'assistance téléphonique.",
    "msg52": "Livraison et ramassage",
    "msg53": "Où livrez - vous?",
    "msg54": "Pour savoir si nous livrons dans votre région, entrez votre Code Postal sur l'article que vous souhaitez acheter. Les produits sont expédiés directement de nos fournisseurs et notre entrepôt mondial est présent dans 112 pays.",
    "msg55": "Aller chercher où?",
    "msg56": "Nous opérons 24h / 24 et 7j / 7 dans nos points de collecte dans les magasins U - Tesco et dans nos entrepôts du monde entier.",
    "msg57": "Pour les articles disponibles pour une livraison rapide, veuillez choisir parmi plusieurs périodes en fonction de votre emplacement. Les commandes seront livrées à domicile et stockées dans un endroit sûr et sécurisé.",
    "msg58": "Questions fréquemment posées",
    "msg59": "Que se passe - t - il si je commande à la fois de grandes et de petites pièces?",
    "msg60": "Le moyen le plus rapide de les livrer est de les expédier séparément. Ne vous inquiétez pas; Vous ne payez qu'une seule fois les frais d'expédition. Veuillez vérifier votre e - mail de confirmation de commande pour plus d'informations.",
    "msg61": "Et si je ne suis pas à la maison?",
    "msg62": "Les petits objets seront laissés dans un endroit sûr et sécurisé, hors de vue ou remis à vos voisins à votre demande. Les objets volumineux ou de valeur nécessitent une photo ou une signature et ne peuvent pas être laissés, donc quelqu'un doit les récupérer à la maison. Si vous ne pouvez pas vous y rendre, contactez - nous via la ligne d'assistance afin que nous puissions reprogrammer les délais de livraison et choisir la date et la période qui vous conviennent le mieux!",
    "msg63": "Service à la clientèle",
    "msg64": "Service à la clientèle en ligne",
    "msg65": "Téléchargement de l'app (côté acheteur)",
    "msg66": "Téléchargement de l'app (côté vendeur)",
    "msg67": "Retours et échanges",
    "msg68": "Politique de confidentialité",
    "msg69": "Politique de retour",
    "msg70": "Livraison et ramassage",
    "msg71": "Centre utilisateur",
    "msg72": "Enregistrement des utilisateurs",
    "msg73": "Connexion du commerçant",
    "msg74": "Accord utilisateur",
    "msg75": "À propos de nous",
    "msg76": "Les xianas",
    "msg77": "Déclaration d'esclave moderne"
  }
};