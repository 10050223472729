var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "h-title" }, [
      _vm._v(_vm._s(_vm.$t("message.msg52")))
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("message.msg53")))
        ]),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("message.msg54")) + " ")])
      ]),
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("message.msg55")))
        ]),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("message.msg56")))]),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("message.msg57")))])
      ]),
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("message.msg58")))
        ]),
        _c("p", { staticStyle: { "font-weight": "600" } }, [
          _vm._v(_vm._s(_vm.$t("message.msg59")))
        ]),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("message.msg60")))]),
        _c("p", { staticStyle: { "font-weight": "600" } }, [
          _vm._v(_vm._s(_vm.$t("message.msg61")))
        ]),
        _c("p", [_vm._v(_vm._s(_vm.$t("message.msg62")))])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-nav-component w-nav",
        attrs: {
          "data-animation": "default",
          "data-collapse": "medium",
          "data-duration": "400",
          "data-easing": "ease",
          "data-easing2": "ease",
          role: "banner"
        }
      },
      [
        _c("div", { staticClass: "avatarkit-container-component" }, [
          _c("div", { staticClass: "avatar-navbar-global-padding-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "avatarkit-navbar-brand w-nav-brand",
                attrs: { href: "/" }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/logo.png"),
                    loading: "lazy",
                    width: "63",
                    alt: ""
                  }
                })
              ]
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: require("../assets/images/header-img-home-02.jpg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }